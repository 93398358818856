import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { change, formValueSelector, getFormValues } from "redux-form";

import { eraseStateProp } from "~/actions/dashboard";
import { getPatientId } from "~/actions/get_patient_id";
import { patientIsSavingSuccess, updatePatient } from "~/actions/post_patient";
import { sendPatientCorrection } from "~/actions/send_patient_correction";
import { addUserActionNotification } from "~/actions/user_notification";
import { hasDeepCBCT, isChildrenShortCourse } from "~/common/courses";
import { convertToJSONFullRx } from "~/common/instructions";
import { remoteLog } from "~/common/logging";
import { assertIsPatient, getLastCorrection, isPatient } from "~/common/patient";
import { IncisorsVerticalOverlap } from "~/common/prescription";
import { canOrderTestPlastic } from "~/common/user";
import { scrollTo } from "~/components/common/ScrollToElement/scrollTo";
import LinksCT, { type ReduxFormLinks } from "~/components/patient/addLinks";
import ImpressionScanOptions from "~/components/patient/impression_scan_options";
import PatientNewInstructionsImages from "~/components/patient/patient_new/patient_new_instructions_images";
import { isOcclusalPlaneValid, PATIENT_NEW_REQUIRED_PHOTOS } from "~/components/patient/patient_new_validation";
import PatientUpdateInstructionsNewMedia from "~/components/patient/patient_update/patient_update_instructions_new_media";
import PatientUpdateInstructionsUploadCt from "~/components/patient/patient_update/patient_update_instructions_upload_ct";
import RecipeFormSmile from "~/components/patient/smile_recipe_redux_form";
import { TestPlaticOptions } from "~/components/patient/test_plastic_options";
import { ConfirmationDialog } from "~/components/ui/confirmation-dialog";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { Loader } from "~/components/ui/loader";
import { LoadingButton } from "~/components/ui/loading-button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

import CorrectionWithCTRadioGroup from "./3d_plan_correction_with_ct";

const mapStateToProps = (state: RootState) => ({
  user: state.user,
  patient: state.patient,
  comments: state.comments,
  media: state.media,
  media_s3: state.media_s3,
  instructions: state.instructions,
  formValues: getFormValues("correction")(state) as TPrescriptionReduxForm | undefined,
  links: getFormValues("links")(state) as ReduxFormLinks | undefined,
  patientSaving: state.patientSaving
});

const mapDispatchToProps = {
  eraseStateProp,
  getPatient: getPatientId,
  addUserActionNotification,
  sendPatientCorrection,
  updatePatient,
  change,
  patientIsSaving: patientIsSavingSuccess,
};

type PlanCancelProps = PropsFromRedux & PlanCancelReduxFormProps & { intl: IntlShape } &
  RouteComponentProps<{ patient_id: string; }>

type PlanCancelReduxFormProps = Partial<
  Pick<
    TPrescriptionReduxForm,
    | "material"
    | "arch"
    | "vertical_overlap"
    | "vertical_overlap_comment"
    | "correction_with_ct"
  >
>;

type PlanCancelState = {
  movment_error: boolean;
  confirmation: number;
  correction_length: number | false;
  showLoader: boolean;
  easy_validate: boolean;
  plan_stage: string | number | null;
  clicked: boolean;
  test_plastic: boolean | null;
};

class PlanCancel extends Component<PlanCancelProps, PlanCancelState> {
  constructor(props: PlanCancelProps) {
    super(props);
    this.state = {
      movment_error: false,
      confirmation: 0,
      correction_length: false,
      showLoader: false,
      easy_validate: false,
      plan_stage: "",
      clicked: false,
      test_plastic: null,
    }
    this.validateStateValue = this.validateStateValue.bind(this);
    this.submitCorrection = this.submitCorrection.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_correction_page");
  }

  async componentDidMount() {
    this.props.eraseStateProp("media");

    if (this.props.user.privileges?.easy_correction_form) {
      this.setState({ easy_validate: true });
    }

    const { patient_id } = this.props.match.params;
    setDocumentTitle(this.props.intl.formatMessage({ id: "treatment.correct.header" }) + " " + patient_id);

    const patient = await this.props.getPatient(Number(patient_id));
    if (patient) {
      const lastCorrection = getLastCorrection(patient);
      this.setState({ test_plastic: lastCorrection.test_plastic });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: PlanCancelProps) {
    const { patient } = nextProps;
    if (patient && patient.course && patient.course.correction.slice(-1)[0].order_options.can_order_correction === false) {
      this.props.history.push("/pages/patients/");
    }
    if (this.state.correction_length == false) {
      if (nextProps.patient && nextProps.patient.course) {
        this.setState({ "correction_length": nextProps.patient.course.correction.length })
      }
    }

    if (nextProps.patient) {
      if (nextProps.patient.course && this.state.correction_length) {
        if (nextProps.patient.course.correction.length > this.state.correction_length && nextProps.patient.patient_id) {
          this.props.history.push("/pages/patients/");
        }
      }
    }

    if (nextProps.user && nextProps.user.privileges && nextProps.user.privileges.easy_correction_form) {
      this.setState({ easy_validate: true })
    }
  }

  UNSAFE_componentWillUpdate(nextProps: PlanCancelProps) {
    if (nextProps.patientSaving === true) {
      this.props.patientIsSaving(false);
      this.props.eraseStateProp("patient");
      this.props.history.push("/pages/patients");
    }
  }

  componentDidUpdate(prevProps: PlanCancelProps) {
    const { patient } = this.props;
    if (prevProps.patient && !Object.keys(prevProps.patient).length && this.props.patient && this.props.patient.course) {
      const plan_stage = patient.course.correction.slice(-1)[0].steps_count_completed;
      if (this.state.plan_stage === 0 || !this.state.plan_stage) {
        if (this.state.plan_stage !== plan_stage && plan_stage > 0) {
          this.setState({ plan_stage: plan_stage });
        }
      }
    } else {
      if (prevProps.patient && Object.keys(prevProps.patient).length > 0 && prevProps.patient.course && prevProps.patient.course.correction) {
        if (this.state.plan_stage === 0 || !this.state.plan_stage) {
          const plan_stage = prevProps.patient.course.correction.slice(-1)[0].steps_count_completed;
          if (this.state.plan_stage != plan_stage && plan_stage > 0) {
            this.setState({ plan_stage: plan_stage });
          }
        }
      }
    }
  }

  validateRequiredFields() {
    assertIsPatient(this.props.patient);
    const { arch, material, vertical_overlap, vertical_overlap_comment, correction_with_ct, patient, media } = this.props;
    const lastCorrection = getLastCorrection(patient);
    const stage = this.state.plan_stage || lastCorrection.steps_count_completed != 0 ? lastCorrection.steps_count_completed : null;
    const isDeepCBCT = hasDeepCBCT(patient);
    const isChildrenShort = isChildrenShortCourse(patient.course.course_id);

    return {
      arch: arch != null,
      material: material != null,
      vertical_overlap_comment: vertical_overlap == IncisorsVerticalOverlap.INCREASE || vertical_overlap == IncisorsVerticalOverlap.DECREASE ? Boolean(vertical_overlap_comment) : null,
      correction_with_ct: !isChildrenShort && isDeepCBCT ? correction_with_ct != null : null,
      stage: !isChildrenShort ? stage != null : null,
      occlusal_plane: isOcclusalPlaneValid(this.props.formValues),
      images: PATIENT_NEW_REQUIRED_PHOTOS.every((item) => media[item] && media[item]?.md5),
    } satisfies Record<string, boolean | null>;
  }

  validateStateValue(e: React.FormEvent<HTMLInputElement>) {
    let stepsTotal = 0;
    if (this.props.patient && this.props.patient.course && this.props.patient.course.correction) {
      stepsTotal = this.props.patient.course.correction[this.props.patient.course.correction.length - 1].steps_count_total;
    }
    if (e.target.value <= 0 || e.target.value > stepsTotal) {
      e.target.value = null
      this.setState({ "plan_stage": null });
    }
    if (e.target.value !== "") {
      this.setState({ "plan_stage": parseInt(e.target.value) });
    }
  }

  async submitCorrection() {
    assertIsPatient(this.props.patient);
    this.setState({ clicked: true });

    const { patient_id } = this.props.patient;
    const rxTypeId = this.props.patient.rx_type_id;

    const linksArray = this.props.links?.links ?? [];
    const links = linksArray.flatMap(link => Object.values(link));

    const media_info = this.props.media;
    const s3_media = this.props.media_s3;

    const prescription = convertToJSONFullRx(this.props.formValues);
    prescription.rx_type_id = rxTypeId;
    const stage = this.state.plan_stage || getLastCorrection(this.props.patient).steps_count_completed;

    try {
      await this.props.sendPatientCorrection(
        patient_id,
        media_info,
        s3_media,
        links,
        stage,
        prescription,
        this.props.correction_with_ct,
        this.state.test_plastic,
      );
    } catch {
      this.setState({ clicked: false });
    }
  }

  render() {
    const { patient } = this.props;

    if (!isPatient(patient)) {
      return <Loader />;
    }

    const lastCorrection = getLastCorrection(patient);
    const stepsTotal = lastCorrection.steps_count_total;
    const stepsCompleted = this.state.plan_stage || lastCorrection.steps_count_completed != 0
      ? lastCorrection.steps_count_completed
      : null;
    const isDeepCBCT = hasDeepCBCT(patient);
    const isChildrenShort = isChildrenShortCourse(patient.course.course_id);
    const optedOutOfTestPlastic = lastCorrection.test_plastic == true && this.state.test_plastic == false;
    const validity = this.validateRequiredFields();

    const submitButtonElement = (
      <LoadingButton
        type="submit"
        variant="primary"
        size="xl"
        className="tw-w-full sm:tw-max-w-[200px]"
        disabled={Object.values(validity).some(v => v == false)}
        style={{ margin: 2 }}
        onClick={optedOutOfTestPlastic ? undefined : () => this.submitCorrection()}
        isLoading={this.state.clicked}
      >
        <FormattedMessage id={isChildrenShort ? "plan.order.btn" : "plan.cancel.btn"} />
      </LoadingButton>
    );

    return (
      <Portlet as="main" id="correction-section">
        <PortletTitle iconClassName="icon-book-open" displayRequiredFieldsInfo>
          <FormattedMessage
            id={isChildrenShort ? "treatment.additional_course.header" : "treatment.correct.header"}
          />
        </PortletTitle>

        <div className="portlet-body">
          {isChildrenShort ? (
            null
          ) : (
            <div id="correction-stage-section" className="form-group">
              <Label id="validation-stage" htmlFor="correction-stage-value" required>
                <FormattedMessage id="plan.cancel.stage" />
              </Label>
              <br />
              <Input
                id="correction-stage-value"
                type="number"
                size={2}
                min={1}
                max={stepsTotal}
                className="tw-inline tw-max-w-[80px]"
                name="stage"
                defaultValue={stepsCompleted}
                onInput={e => this.validateStateValue(e)}
              />
              <span>
                  {" "}<FormattedMessage id="pat_table.of" /> {stepsTotal}
              </span>
            </div>
          )}

          <br />

          <ImpressionScanOptions />
          {canOrderTestPlastic(this.props.user) && lastCorrection.test_plastic != null ? (
            <TestPlaticOptions
              value={this.state.test_plastic}
              onValueChange={(testPlastic) => this.setState({ test_plastic: testPlastic })}
            />
          ) : null}

          {isChildrenShort ? <br /> : null}
          {!isChildrenShort && isDeepCBCT ? (
              <>
                <CorrectionWithCTRadioGroup />
                <br />
              </>
          ) : null}
          <PatientNewInstructionsImages displayHeading={false} correctionNumber={patient.course.correction.length} />
          <PatientUpdateInstructionsNewMedia has_medit_files={false} />
          <PatientUpdateInstructionsUploadCt />
          <LinksCT />
          <RecipeFormSmile course_id={patient.course.course_id} />
          <CheckList validity={validity} />
          <br />
          {optedOutOfTestPlastic ? (
            <ConfirmationDialog
              title={<FormattedMessage id="pat.status.button.confirm" />}
              description={
                <FormattedMessage
                  id="plan.cancel.opt_out_test_plastic.description"
                  values={{ bold: (chunks) => <strong>{chunks}</strong> }}
                />
              }
              actionText={
                <FormattedMessage id={isChildrenShort ? "plan.order.btn" : "plan.cancel.btn"} />
              }
              onActionClick={(closeDialog) => (closeDialog(), this.submitCorrection())}
            >
              {submitButtonElement}
            </ConfirmationDialog>
          ) : (
            submitButtonElement
          )}
        </div>
      </Portlet>
    )
  }
}

const selector = formValueSelector("correction")

PlanCancel = connect(
  (state) => {
    const material = selector(state, "material")
    const arch = selector(state, "arch")
    const vertical_overlap = selector(state, "vertical_overlap")
    const vertical_overlap_comment = selector(state, "vertical_overlap_comment")
    const correction_with_ct = selector(state, "correction_with_ct");

    return {
      material,
      arch,
      vertical_overlap,
      vertical_overlap_comment,
      correction_with_ct,
    };
  }
)(PlanCancel)


const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanCancel));

function CheckList({
  validity,
}: {
  validity: ReturnType<PlanCancel["validateRequiredFields"]>;
}) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-6 tw-py-3">
      {validity.stage != null ? (
        <CheckListItem
          intlId="recipe.check.reference.steps"
          toId="validation-stage"
          isValid={validity.stage}
        />
      ) : null}

      <CheckListItem
        intlId="recipe.check.reference.material"
        toId="validation-material"
        isValid={validity.material}
      />

      {validity.correction_with_ct != null ? (
        <CheckListItem
          intlId="recipe.check.reference.correction_with_ct"
          toId="validation-correction-with-ct"
          isValid={validity.correction_with_ct}
        />
      ) : null}

      <CheckListItem
        intlId="recipe.check.reference.photos"
        toId="profile"
        isValid={validity.images}
      />

      <CheckListItem
        intlId="recipe.check.reference.arches"
        toId="validation-arch"
        isValid={validity.arch}
      />

      <CheckListItem
        intlId="TA_OCCLUSAL"
        toId="validation-occlusal_plane"
        isValid={validity.occlusal_plane}
      />

      {validity.vertical_overlap_comment != null ? (
        <CheckListItem
          intlId="INCISORS_VO"
          toId="incisors-vert-overlap-label"
          isValid={validity.vertical_overlap_comment}
        />
      ) : null}
    </div>
  );
}

function CheckListItem({
  intlId,
  toId,
  isValid,
}: {
  intlId: MessageDescriptor["id"];
  toId: string;
  isValid: boolean;
}) {
  const doneStyles = { color: "#82D9D4", fontWeight: 700 };
  const unDoneStyles = { color: "red", fontWeight: 500 };

  return (
    <div className="scroll-block tw-w-fit">
      <FormattedMessage tagName="p" id="plan.cancel.scroll.to.field" />

      <button
        className="scrollBtn tw-flex tw-items-center tw-gap-2"
        onClick={() => scrollTo({ id: toId, duration: 1500 })}
      >
        <i className="icon-check" style={isValid ? doneStyles : unDoneStyles} />
        <FormattedMessage id={intlId} />
      </button>
    </div>
  );
}
