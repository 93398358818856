import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import type { RouteComponentProps } from "react-router-dom";
import { getFormValues, isDirty } from "redux-form";

import { eraseStateProp } from "~/actions/dashboard";
import { getToken } from "~/actions/get_token";
import {
  createAndSubmitPatient,
  createPatient,
  patientIsSavingSuccess,
  type TCreatePatientInstructions,
} from "~/actions/post_patient";
import { RxType } from "~/common/constants";
import { isRetainersCourse } from "~/common/courses";
import { convertToJSONFullRx } from "~/common/instructions";
import { remoteLog } from "~/common/logging";
import { assertIsUser, canOrderTestPlastic, isUser } from "~/common/user";
import { scrollTo } from "~/components/common/ScrollToElement/scrollTo";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { LoadingButton } from "~/components/ui/loading-button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import { getCourseInstallment } from "~/slices/course-installment";
import { addInstructions, createInstructionsSuccess } from "~/slices/instructions";
import type { RootState } from "~/store";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

import LinksCT, { type ReduxFormLinks } from "./addLinks";
import PatientNewCourse from "./patient_new/patient_new_course";
import { PatientNewDoctorInfo } from "./patient_new/patient_new_doctor_info";
import { PatientNewInstructionsFiles } from "./patient_new/patient_new_instructions_files";
import PatientNewInstructionsImages from "./patient_new/patient_new_instructions_images";
import PatientNewPatientInfo from "./patient_new/patient_new_patient_info";
import PatientNewPayment from "./patient_new/patient_new_payment";
import {
  PATIENT_NEW_REQUIRED_FIELDS,
  type TPatientNewRequiredField,
  validateArch,
  validateClinicAndPaymentMethodAndCourse,
  validateEmail,
  validateLinks,
  validateMaterial,
  validateOcclusalPlane,
  validatePatientName,
  validatePatientPayerNameForChildrenOrTeenCourse,
  validatePayer,
  validatePaymentOption,
  validatePhotos,
  validateTestPlastic,
  validateVerticalOverlapComment,
} from "./patient_new_validation";
import PatientUpdateInstructionsUploadCt from "./patient_update/patient_update_instructions_upload_ct";
import RecipeFormShortRX from "./recipe_redux_form_short_rx";
import RouteLeavingGuard from "./route_leaving_guard";
import RecipeFormSmile from "./smile_recipe_redux_form";

const ScrollToField = (toId: string) => {
  scrollTo({ id: toId, duration: 1500 });
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patientSaving: state.patientSaving,
    instructions: state.instructions,
    media: state.media,
    media_s3: state.media_s3,
    clinics: state.clinics,
    formValues: getFormValues("correction")(state) as TPrescriptionReduxForm,
    links: getFormValues("links")(state) as ReduxFormLinks | undefined,
    dirtyCorrection: isDirty("correction")(state),
    dirtyLinks: isDirty("links")(state),
  };
};

const mapDispatchToProps = {
  createAndSubmitPatient,
  createPatient,
  patientIsSaving: patientIsSavingSuccess,
  initInstrucrions: createInstructionsSuccess,
  getToken,
  eraseStateProp,
  getCourseInstallment,
  addInstruction: addInstructions,
};

type PatientNewProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps;

type PatientNewState = {
  isSubmitPending: boolean;
  isDraftPending: boolean;
  isDirty: boolean;
  currentPath: string | null;
  submit: boolean;
};

class PatientNew extends Component<PatientNewProps, PatientNewState> {
  constructor(props: PatientNewProps) {
    super(props);
    this.state = {
      isSubmitPending: false,
      isDraftPending: false,
      isDirty: false,
      currentPath: null,
      submit: false,
    };
    this.submitButton = this.submitButton.bind(this);
    this.submitSaveButton = this.submitSaveButton.bind(this);
    this.setDirty = this.setDirty.bind(this);
  }

  async submitButton() {
    assertIsUser(this.props.user);

    this.setState({ submit: true });
    if ($("#submit-pacient-btn").attr("disabled")) return;

    const { user, instructions, formValues } = this.props;
    const rxTypeId = user.preferences.rx_type_id;
    const emptyFields: TPatientNewRequiredField[] = [];

    const linksArray = this.props.links?.links ?? [];
    const links = linksArray.flatMap((link) => Object.values(link));

    const instructionsCopy: TCreatePatientInstructions = { ...instructions };

    if (this.props.clinics.length == 1 && !instructions.clinic_id) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      instructionsCopy.clinic_id = Number(this.props.clinics[0]!.clinic_id);
    }

    const isPatientNameValid = validatePatientName(instructions, emptyFields);
    const isMaterialValid = validateMaterial(formValues, emptyFields);
    const isVerticalOverlapCommentValid = validateVerticalOverlapComment(formValues, emptyFields);
    const isTestPlasticValid = canOrderTestPlastic(user)
      ? validateTestPlastic(instructions, emptyFields)
      : true;
    const isEmailValid = validateEmail(instructions, emptyFields);
    const isPayerValid = validatePayer(instructions, emptyFields);
    const isPaymentOptionValid = validatePaymentOption(instructions, emptyFields);
    const areClinicAndPaymentMethodAndCourseValid = validateClinicAndPaymentMethodAndCourse(
      instructions,
      emptyFields,
    );
    const areLinksValid = validateLinks(links, emptyFields);
    const isArchValid = validateArch(formValues, emptyFields);
    const isPatientPayerNameValidForChildrenOrTeenCourse =
      validatePatientPayerNameForChildrenOrTeenCourse(instructions, emptyFields);
    const isOcclusalPlaneValid = validateOcclusalPlane(instructions, formValues, emptyFields);
    const arePhotosValid = validatePhotos(instructions, this.props.media, emptyFields);
    const isBirthDateValid = !instructions.bdate || instructions.bdate !== "Invalid date";

    const areRequiredFieldsValid =
      isPatientNameValid &&
      isMaterialValid &&
      isVerticalOverlapCommentValid &&
      isTestPlasticValid &&
      isEmailValid &&
      isPayerValid &&
      isPaymentOptionValid &&
      areClinicAndPaymentMethodAndCourseValid &&
      areLinksValid &&
      isArchValid &&
      isPatientPayerNameValidForChildrenOrTeenCourse &&
      isOcclusalPlaneValid &&
      arePhotosValid &&
      isBirthDateValid;

    if (!areRequiredFieldsValid) {
      const scrollToFields = PATIENT_NEW_REQUIRED_FIELDS.filter((f) => emptyFields.includes(f));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ScrollToField(scrollToFields[0]!);
      return;
    }

    instructionsCopy.prescription = convertToJSONFullRx(formValues);
    instructionsCopy.prescription.rx_type_id = rxTypeId;
    instructionsCopy.links = links;

    this.setState({ isSubmitPending: true });

    try {
      await this.props.createAndSubmitPatient(
        instructionsCopy,
        this.props.media,
        this.props.media_s3,
      );
    } catch {
      this.setState({ isSubmitPending: false });
    }
  }

  async submitSaveButton() {
    assertIsUser(this.props.user);
    _paq.push(["trackEvent", "New patient", "Save as a draft"]);

    this.setState({ submit: true });
    if ($("#save-pacient-btn").attr("disabled")) return;

    const { user, instructions, formValues } = this.props;
    const rxTypeId = user.preferences.rx_type_id;
    const emptyFields: TPatientNewRequiredField[] = [];

    const linksArray = this.props.links?.links ?? [];
    const links = linksArray.flatMap((link) => Object.values(link));

    const isPatientNameValid = validatePatientName(instructions, emptyFields);
    const isVerticalOverlapCommentValid = validateVerticalOverlapComment(formValues, emptyFields);
    const areLinksValid = validateLinks(links, emptyFields);

    if (!(isPatientNameValid && isVerticalOverlapCommentValid && areLinksValid)) {
      const scrollToFields = PATIENT_NEW_REQUIRED_FIELDS.filter((f) => emptyFields.includes(f));
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ScrollToField(scrollToFields[0]!);
      return;
    }

    const instructionsCopy: TCreatePatientInstructions = { ...instructions };

    instructionsCopy.prescription = convertToJSONFullRx(formValues);
    instructionsCopy.prescription.rx_type_id = rxTypeId;
    instructionsCopy.links = links;

    this.setState({ isDraftPending: true, isDirty: false });

    try {
      await this.props.createPatient(instructionsCopy, this.props.media, this.props.media_s3);
    } catch {
      this.setState({ isDraftPending: false });
    }
  }

  componentDidMount() {
    this.props.initInstrucrions();
    this.props.getToken();
    this.props.eraseStateProp("patient");
    this.props.eraseStateProp("instructions");
    this.props.eraseStateProp("media");
    void this.props.getCourseInstallment();

    const { user } = this.props;

    _paq.push(["HeatmapSessionRecording::enable"]);
    if (user && user.account_id) {
      _paq.push(["setUserId", user.account_id.toString()]);
    }
    this.setState({ currentPath: window.location.pathname });
    if (isUser(this.props.user)) {
      this.props.addInstruction({ rx_type_id: this.props.user.preferences.rx_type_id });
    }
    setDocumentTitle(this.props.intl.formatMessage({ id: "left.menu.add_patient" }));
  }

  UNSAFE_componentWillUpdate(nextProps: PatientNewProps) {
    if (nextProps.patientSaving === true) {
      this.props.patientIsSaving(false);
      this.props.eraseStateProp("patient");
      this.props.history.push("/pages/patients");
    }
  }

  componentDidUpdate(prevProps: PatientNewProps) {
    // NOTE: runs after you click on logout and user becomes an empty object, hence we
    // need to check for the existence of the preferences property before accessing rx_type_id
    if (prevProps.user !== this.props.user && this.props.user.preferences) {
      this.props.addInstruction({ rx_type_id: this.props.user.preferences.rx_type_id });
    }
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
    const { isDirty, submit } = this.state;
    const formIsDirty =
      !submit &&
      (isDirty ||
        dirtyCorrection ||
        dirtyLinks ||
        Object.keys(media).length >= 1 ||
        (media_s3.files && media_s3.files.length >= 1));
    if (formIsDirty) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_new");
  }

  setDirty(key: string, data) {
    const { instructions } = this.props;
    if (data !== "" || instructions[key] !== "") {
      this.setState({ isDirty: true });
    } else {
      this.setState({ isDirty: false });
    }
  }

  render() {
    if (!isUser(this.props.user)) {
      return <Loader />;
    }

    const rxTypeId = this.props.user.preferences.rx_type_id;
    const course_id = this.props.instructions ? this.props.instructions.course_id : null;
    const { isDirty, currentPath, submit } = this.state;
    const { dirtyCorrection, dirtyLinks, media, media_s3 } = this.props;
    const formIsDirty =
      !submit &&
      (isDirty ||
        dirtyCorrection ||
        dirtyLinks ||
        Object.keys(media).length >= 1 ||
        (media_s3.files && media_s3.files.length >= 1));

    return (
      <Layout>
        <Portlet as="main">
          <PortletTitle iconClassName="icon-book-open" displayRequiredFieldsInfo>
            <FormattedMessage id="left.menu.add_patient" />
          </PortletTitle>

          <RouteLeavingGuard
            when={formIsDirty}
            shouldBlockNavigation={() => formIsDirty}
            saveChanges={this.submitSaveButton}
            saveAsADraft={true}
            currentPath={currentPath}
          />

          <div id="add-patient-form" className="portlet-body form">
            <div className="form-body">
              <PatientNewDoctorInfo setDirty={this.setDirty} />
              <PatientNewPayment setDirty={this.setDirty} />
              <PatientNewPatientInfo setDirty={this.setDirty} />
              <PatientNewCourse setDirty={this.setDirty} rxTypeId={rxTypeId} />
              <PatientNewInstructionsFiles />
              {isRetainersCourse(course_id) ? null : (
                <PatientUpdateInstructionsUploadCt setDirty={this.setDirty} />
              )}
              {isRetainersCourse(course_id) ? null : <LinksCT />}
              {isRetainersCourse(course_id) ? null : <PatientNewInstructionsImages />}
              {rxTypeId == RxType.STANDARD ? (
                <RecipeFormSmile course_id={course_id} />
              ) : (
                <RecipeFormShortRX course_id={course_id} />
              )}
            </div>

            <div className="tw-flex tw-flex-wrap tw-gap-3 tw-border-t tw-border-[#e7ecf1] tw-py-5 sm:tw-gap-2">
              <LoadingButton
                id="submit-pacient-btn"
                type="button"
                variant="primary"
                size="xl"
                className="tw-w-full sm:tw-max-w-[180px]"
                disabled={this.state.isDraftPending}
                onClick={() => this.submitButton()}
                isLoading={this.state.isSubmitPending}
              >
                <FormattedMessage id="BTN_SUBMIT_NEW" />
              </LoadingButton>

              <LoadingButton
                id="save-pacient-btn"
                type="button"
                variant="secondary"
                size="xl"
                className="tw-w-full sm:tw-max-w-[180px]"
                disabled={this.state.isSubmitPending}
                onClick={() => this.submitSaveButton()}
                isLoading={this.state.isDraftPending}
              >
                <FormattedMessage id="BTN_SAVE_DRAFT" />
              </LoadingButton>
            </div>
          </div>
        </Portlet>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientNew));
