import clsx from "clsx";
import React from "react";

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type IconButtonProps = Pick<HTMLButtonProps, "onClick" | "children"> & {
  srOnlyText: React.ReactChild;
};

export function IconButton({ srOnlyText, onClick, children }: IconButtonProps) {
  return (
    <button
      type="button"
      className={clsx(
        "tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-bg-transparent",
        "tw-h-9 tw-w-9 tw-border-none tw-transition-colors hover:tw-bg-[#f4f4f5] active:tw-bg-[#f4f4f5]",
      )}
      onClick={onClick}
    >
      {children}

      <span className="tw-sr-only">{srOnlyText}</span>
    </button>
  );
}
