import React, { Component, createRef } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import Pagination from "react-js-pagination";
import { connect, type ConnectedProps } from "react-redux";
import { NavLink, type RouteComponentProps } from "react-router-dom";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { getDoctorsPays, getDoctorsTasks, getPatientsData } from "~/actions/dashboard";
import { getAllShipments } from "~/actions/deliveries";
import { getToken } from "~/actions/get_token";
import precise from "~/assets/img/precise.svg";
import {
  DN_MAP_PALMER,
  PLAN_LANG_OPTION_MAP,
  statuses,
  statuses_predict,
} from "~/common/constants";
import { Status } from "~/common/courses";
import { remoteLog } from "~/common/logging";
import { deployedRussia, deployedUSA } from "~/common/utils";
import { FormatDate } from "~/components/common/FormatDate";
import { FormatNumber } from "~/components/common/FormatNumber";
import { PersonName } from "~/components/common/PersonName";
import { notificationCustomStyle } from "~/components/notifications/notification_styles";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { PatientStatusBadge } from "~/components/ui/patient-status-badge";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { PLAN_LINK_V2, PLAN_LINK_V2_RU } from "~/config";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { TPatientList } from "~/reducers/dashboard";
import type { RootState } from "~/store";
import type { TCourse, TPatient } from "~/types/patient";

import PatientBonuses from "./patient_bonuses";
import { CourseName } from "./patient_show/patient-card/patient-card-course";


const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patients: state.patients,
    spinner: state.spinner,
    lang: state.intl,
    token: state.token,
  };
};

const mapDispatchToProps = {
  getData: getPatientsData,
  getTasks: getDoctorsTasks,
  getPays: getDoctorsPays,
  getShipments: getAllShipments,
  getToken,
};

type PatientsListNewProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps;

type PatientsListNewState = {
  filteredPatients: TPatientList[];
  paymentState: boolean;
  payment_alert: boolean;
  sort: {
    order: "desc",
    name: "patient_id",
  };
  status: string | false;
  ordering: "patient_id" | "-patient_id" | "patient_name" | "-patient_name";
  search: string | false;
  page: number;
  perPage: number;
  selectedOptions: { value: TCourse["course_id"]; label: TPatient["status"]; }[];
};

class PatientsListNew extends Component<PatientsListNewProps, PatientsListNewState> {
  searchInputRef = createRef<HTMLInputElement>();

  static initialState = {
    perPage: parseInt(window.localStorage.getItem("perPage") ?? "", 10) || 100,
  };

  constructor(props: PatientsListNewProps) {
    super(props);
    this.state = {
      filteredPatients: props.patients.patients,
      paymentState: false,
      payment_alert: false,
      sort: {
        order: "desc",
        name: "patient_id",
      },
      status: false,
      ordering: "-patient_id",
      search: false,
      page: this.getPageFromURL() ?? 1,
      perPage: PatientsListNew.initialState.perPage,
      selectedOptions: []
    };
    this.togglePayment = this.togglePayment.bind(this);
    this.renderPrice = this.renderPrice.bind(this);
    this.fm = this.fm.bind(this);
    this.submit = this.submit.bind(this);
    this.clear = this.clear.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
    this.open3dPlan = this.open3dPlan.bind(this);
    this.onSelectPerPage = this.onSelectPerPage.bind(this);
  }

  getPageFromURL() {
    const searchParams = new URLSearchParams(this.props.location.search);
    const maybePageAsString = searchParams.get("page");

    if (maybePageAsString) {
      const pageAsNumber = Number(maybePageAsString);
      if (!Number.isNaN(pageAsNumber) && pageAsNumber > 0) {
        return pageAsNumber;
      }
    }

    return null;
  }

  syncPageInURL() {
    const searchParams = new URLSearchParams(this.props.location.search);
    searchParams.set("page", String(this.state.page));
    this.props.history.replace({ search: searchParams.toString() });
  }

  componentDidUpdate(_: PatientsListNewProps, prevState: PatientsListNewState) {
    if (this.state.page !== prevState.page) {
      this.syncPageInURL();
    }
  }

  componentDidMount() {
    this.syncPageInURL();
    const { user } = this.props;

    _paq.push(['HeatmapSessionRecording::enable']);
    if (user && user.account_id) {
        _paq.push(['setUserId', user.account_id.toString()]);
    }
    if (this.props.user && Object.keys(this.props.user).length > 0) {
      this.props.getShipments();
    }
    this.props.getToken();
    this.props.getTasks();
    if (deployedUSA() || (deployedRussia() && this.props.intl.locale === "ru"))
      this.props.getPays();
      this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage
      );
    window.scrollTo(0, 0);
    setDocumentTitle(this.props.intl.formatMessage({ id: "pat_table.block_head" }))
  }

  fm(id: MessageDescriptor["id"]) {
    return this.props.intl.formatMessage({ id: id });
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patients_list");
  }

  UNSAFE_componentWillReceiveProps(next_props: PatientsListNewProps) {
    if (this.props.user.account_id && !next_props.user.account_id) {
      this.props.history.push("/");
    }
    this.setState({ filteredPatients: next_props.patients.patients });
    if (next_props.patients.payment_alert)
      this.setState({ payment_alert: true });
  }

  open3dPlan(id: TPatient["patient_id"]) {
    const { user } = this.props;
    let langLocale = this.props.lang.locale;
    if (deployedUSA() === true) {
      langLocale = PLAN_LANG_OPTION_MAP[user.preferences.dental_notation] || PLAN_LANG_OPTION_MAP[DN_MAP_PALMER];
    }
    if (window.location.hostname.endsWith(".com")) {
      window.open(PLAN_LINK_V2(id, this.props.token, langLocale))
    } else {
      window.open(PLAN_LINK_V2_RU(id, this.props.token, langLocale))
    }
  }

  handleFilterChange(str: React.KeyboardEvent<HTMLInputElement>) {
      const string = str.target.value
        .trimEnd()
        .trimStart()
        .toString()
        .toLowerCase();
      if (str.target.value.length === 0) {
        this.setState({ search: false });
        this.props.getData(
          this.state.status,
          this.state.ordering,
          this.state.search,
          this.state.page,
          this.state.perPage
        );
      } else {
        this.setState({ search: string });
      }
  }

  handleEnterSearch(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      const str = event.target.value.trimEnd().trimStart();
      if (str === "") {
        this.setState({ search: false });
      } else {
        this.setState({ search: str });
      }
      this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage
      );
    }
  }

  togglePayment() {
    this.setState({
      paymentState: !this.state.paymentState,
    });
  }

  outOf(data: TPatientList["latest_correction"]) {
    if (
      data.steps_count_total !== "None" &&
      data.steps_count_completed !== "None"
    ) {
      return (
        <span>
          {data.steps_count_completed + " "}
          <FormattedMessage id="pat_table.of" />
          {" " + data.steps_count_total}
        </span>
      );
    } else {
      return null;
    }
  }

  renderPrice(value: number) {
    if ((value && value === "None") || (!value && value !== 0)) {
      return "";
    }
    return <FormatNumber value={value} />;
  }

  onSelect(options: PatientsListNewState["selectedOptions"]) {
    this.setState({ selectedOptions: options });
    let status = "";
    options.forEach((x) => {
      if (
        deployedRussia() &&
        this.props.intl.locale == "ru" &&
        [7, 8, 9].includes(x.value)
      ) {
        status = `${status}7,8,9,`;
      } else if (
        (deployedUSA() || this.props.intl.locale == "en") &&
        [7, 8, 9, 10].includes(x.value)
      ) {
        status = `${status}7,8,9,10,`;
      } else if (x.value === 14 || x.value === 15 || x.value === 17) {
        status = `${status}14,15,17,`;
      } else {
        status = `${x.value},${status}`;
      }
    });
    this.setState({ status: status.slice(0, status.length - 1) });
    if (status === "") {
      this.clear();
    }
  }

  submit() {
    this.setState({ filteredPatients: [] });
    this.setState({ page: 1 });
    this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage
    );
  }

  clear() {
    this.setState({
      status: false,
      ordering: "-patient_id",
      search: false,
      page: 1,
      perPage: PatientsListNew.initialState.perPage,
      selectedOptions: [],
    });
    if (this.searchInputRef.current) {
      this.searchInputRef.current.value = "";
    }
    this.props.getData();
  }

  onSelectPerPage(newPerPage: number) {
    this.setState({ perPage: newPerPage });
    window.localStorage.setItem("perPage", `${newPerPage}`);

    this.props.getData(
      this.state.status,
      this.state.ordering,
      this.state.search,
      this.state.page,
      newPerPage
    );
  }

  onChangeSort(name: "patient_id" | "patient_name") {
    if (name === "patient_id") {
      this.setState({ ordering: this.state.ordering === name ? "-patient_id" : "patient_id" });
    } else if (name === "patient_name") {
      this.setState({ ordering: this.state.ordering === name ? "-patient_name" : "patient_name" });
    }
      this.props.getData(
        this.state.status,
        this.state.ordering,
        this.state.search,
        this.state.page,
        this.state.perPage
      );
  }

  handlePageClick(pageNum: number) {
    window.scrollTo(0, 0);
    this.setState({
      page: pageNum,
    });
    this.props.getData(
      this.state.status,
      this.state.ordering,
      this.state.search,
      pageNum,
      this.state.perPage
    );
  }

  render() {
    const showStepsDelivered = deployedRussia() === true;
    const animatedComponents = makeAnimated();
    const { selectedOptions, filteredPatients, page, perPage } = this.state;
    const status_list = (deployedUSA() || this.props.intl.locale == "en") ? statuses_predict : statuses;

    return (
        <Layout notificationStyle={notificationCustomStyle}>
          <Portlet as="main">
            <PortletTitle iconClassName="icon-users" id="patients-table-block-title">
              <FormattedMessage id="pat_table.block_head" />
            </PortletTitle>

            <PatientBonuses />
            <div className="portlet-body">
              <div
                id="doctor_pacients_table_wrapper"
                className="dataTables_wrapper no-footer"
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <Select
                      defaultValue={selectedOptions}
                      value={selectedOptions}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={status_list}
                      getOptionLabel={(option) => this.fm(option.label)}
                      getOptionValue={(option) => option.value}
                      onChange={(options) => this.onSelect(options)}
                      placeholder={<FormattedMessage id="table.status" />}
                    />
                  </div>
                  <div className="col-md-3 col-xs-7">
                    <Input
                      ref={this.searchInputRef}
                      name="search"
                      maxLength={128}
                      onKeyUp={(e) => this.handleFilterChange(e)}
                      type="search"
                      aria-controls="doctor_pacients_table"
                      placeholder={deployedUSA() ? "Search" : "Поиск"}
                      style={{ height: "38px" }}
                      onKeyDown={(e) => this.handleEnterSearch(e)}
                    />
                  </div>
                  <div className="col-md-1 col-xs-1">
                    <button
                      style={{ backgroundColor: "#efefef", marginLeft: "-16px" }}
                      type="submit"
                      onClick={this.submit}
                      className="btn btn-circle hover:tw-bg-[#e0e0e0]"
                      title={deployedUSA() ? "Search" : "Поиск"}
                    >
                      <span className="glyphicon glyphicon-search"></span>
                    </button>
                  </div>
                  <div className="col-md-1 col-xs-2">
                    <button
                      style={{ backgroundColor: "#efefef" }}
                      type="reset"
                      onClick={this.clear}
                      className="btn btn-circle hover:tw-bg-[#e0e0e0]"
                      title={deployedUSA() ? "Clear" : "Очистить"}
                    >
                      <span className="glyphicon glyphicon-refresh"></span>
                    </button>
                  </div>
                </div>
                <div className="row" style={{ paddingTop: "10px" }}>
                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                    <Button
                      onClick={this.togglePayment}
                      variant={this.state.payment_alert ? "danger" : "default"}
                    >
                      <FormattedMessage id={this.state.paymentState ? "general.payment.close" : "general.payment.show" } />
                    </Button>
                  </div>
                </div>
                <div className="table-scrollable">
                  {!this.props.spinner ? (
                    <table
                      className="table table-bordered table-hover dataTable no-footer"
                      id="doctor_pacients_table"
                      role="grid"
                    >
                      <thead id="patients-table-head">
                        <tr role="row">
                          <th
                            className={
                              this.state.ordering === "patient_id"
                                ? "sorting_asc"
                                : "sorting_desc"
                            }
                            style={{ width: 14 }}
                            onClick={() => this.onChangeSort("patient_id")}
                          >
                            {" "}
                            #
                          </th>
                          <th
                            className={
                              this.state.ordering === "patient_name"
                                ? "sorting_asc"
                                : "sorting_desc"
                            }
                            style={{ width: 111 }}
                            onClick={() => this.onChangeSort("patient_name")}
                          >
                            <span style={{ marginRight: '12px' }}><FormattedMessage id="pat_table.name" /></span>
                          </th>
                          <th style={{ width: 82 }}>
                            <FormattedMessage id="HEADER_INSTRUCTIONS" />
                          </th>
                            {showStepsDelivered
                            ? (
                                <th style={{ width: 150 }}>
                                    <FormattedMessage id="pat_table.delivered" />
                                </th>
                              ) : null
                            }
                          <th style={{ width: 79 }}>
                            <FormattedMessage id="pat_table.received" />
                          </th>
                          <th style={{ width: 113 }}>
                            <FormattedMessage id="pat_table.tot_payment" />
                          </th>
                          <th style={{ width: 141 }}>
                            <FormattedMessage id="pat_table.status" />
                          </th>
                        </tr>
                      </thead>
                      <tbody id="patients-table-body">
                        {filteredPatients.map((patient, i) => {
                          const lastCorrection = patient.latest_correction;
                          const { total, paid } = patient.total_payments;

                          return (
                            <tr
                              key={"p" + i}
                              className={"clickable-row " + (i % 2 ? "even" : "odd")}
                              role="row"
                            >
                              <td style={{ textAlign: "center" }} className="sorting_1">
                                {patient.patient_id}
                                {patient.precise_initial_adjust_timestamp !== null ? (
                                    <div className="precise_tip">
                                      <img style={{ width: "50px", margin: "0 auto" }} src={precise} alt="3D Precise" />
                                      <span className="precise_tooltip">
                                        <FormattedMessage
                                          id="tooltip_precise_first_comment"
                                          values={{
                                            labelTitle: <FormatDate value={patient.precise_initial_adjust_timestamp} date time />
                                          }}
                                        />
                                        <br />
                                        <FormattedMessage
                                          id="tooltip_precise_last_comment"
                                          values={{
                                            labelTitle: <FormatDate value={patient.precise_latest_adjust_timestamp} date time />
                                          }}
                                        />
                                      </span>
                                    </div>
                                 ) : null}
                              </td>
                              <td>
                                <NavLink to={`/pages/patient/${patient.patient_id}`}>
                                  <PersonName person={patient} />
                                </NavLink>
                              </td>
                              <td>
                                <CourseName patient={patient} />
                              </td>
                                {showStepsDelivered ? (
                                    <td>
                                        {lastCorrection.steps_count_total &&
                                        (lastCorrection.steps_count_completed ||
                                          lastCorrection.steps_count_completed === 0)
                                          ? this.outOf(lastCorrection)
                                          : null}
                                    </td>
                                ): null}
                              <td>
                                {this.state.paymentState ? this.renderPrice(paid) : null}
                              </td>
                              <td>
                                {this.state.paymentState ? this.renderPrice(total) : null}
                              </td>
                              <td>
                                {this.props.token && patient.latest_correction.status == Status._3D_PLAN_APPROVAL ? (
                                    <button
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title={this.fm("tooltip.click.approve")}
                                      onClick={() => this.open3dPlan(patient.patient_id)}
                                      className="btn btn-danger"
                                    >
                                      <FormattedMessage id="S_3D_PLAN_APPROVAL" />
                                      {deployedRussia() ? <i className="icon-action-redo" style={{ marginLeft: "5px" }} /> : null}
                                    </button>
                                  ) : <PatientStatusBadge status={patient.latest_correction.status} />}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <Loader />
                  )}
                </div>
                <div className="row">
                  <div className="col-md-5">
                    <Pagination
                      totalItemsCount={this.props.patients.total}
                      onChange={this.handlePageClick}
                      activePage={page}
                      itemsCountPerPage={perPage}
                      pageRangeDisplayed={3}
                    />
                  </div>
                  <PageSizes perPage={perPage} onSelectPerPage={this.onSelectPerPage} />
                </div>
              </div>
            </div>
          </Portlet>
        </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PatientsListNew));

function PageSizes({
  perPage,
  onSelectPerPage,
}: {
  perPage: number;
  onSelectPerPage(newPerPage: number): void;
}) {
  type TSize = {
    value: number;
    intlId: MessageDescriptor["id"];
  };

  const SIZES: TSize[] = [
    { value: 50, intlId: "fifty" },
    { value: 100, intlId: "hundred" },
    { value: 200, intlId: "two.hundred" },
  ];

  return (
    <div className="col-md-offset-10 col-xs-offset-1 col-sm-offset-1">
      <ul className="pagination" id="pages">
        {SIZES.map((size) => (
          <li key={size.value}>
            <button
              onClick={() => onSelectPerPage(size.value)}
              disabled={perPage === size.value}
              className="btn btn-pagination"
              style={perPage === size.value ? { backgroundColor: "#eaecf3" } : {}}
            >
              <FormattedMessage id={size.intlId} />
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
