import type { SOArch } from "~/common/constants";
import { myFetch } from "~/common/fetch";
import { API_POST_ADDITIONAL_ALIGNERS } from "~/config";
import type { TUserActionNotification } from "~/reducers/dashboard";
import type { AppDispatch } from "~/store";
import type { TPatient } from "~/types/patient";

import { addUserActionNotification } from "./user_notification";

export function orderAdditionalAligners(
  patient_id: TPatient["patient_id"],
  stage: number,
  amount: number,
  treat_arch_id: SOArch,
  elastics_left: number,
  elastics_right: number,
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.additional.aligners.ok",
    level: "success",
    position: "tl",
    autoDismiss: 7,
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.additional.aligners.error",
    level: "error",
    position: "tl",
    autoDismiss: 7,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const response = await myFetch(API_POST_ADDITIONAL_ALIGNERS(patient_id), {
        method: "POST",
        body: JSON.stringify({
          stage: stage,
          amount: amount,
          treat_arch_id: treat_arch_id,
          elastics_left: elastics_left,
          elastics_right: elastics_right,
        }),
      });

      if (!response.ok) {
        throw new Error("Error occurred trying to order additional aligners");
      }

      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      window.location.href = `/pages/patient/${patient_id}`;
    } catch (err) {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      throw err;
    }
  };
}
