import type { MessageDescriptor } from "react-intl";
import type { Notification as ReactNotificationType } from "react-notification-system";
import { v4 as uuidv4 } from "uuid";

import type { TClinic } from "~/types/clinic";
import type { TNone } from "~/types/common";
import type { TDelivery } from "~/types/delivery";
import type { TCorrection, TCorrectionMedia, TCorrectionMediaSemantics, TPatient } from "~/types/patient";
import type { TService } from "~/types/service";
import type { TUser } from "~/types/user";

import type { getBannersAction } from "../actions/banners";
import {
  CREATE_SHIPMENT,
  CREATE_SHIPMENT_ERROR,
  CREATE_SHIPMENT_SUCCESS,
  GET_DELIVERIES,
  GET_DELIVERIES_ERROR,
  GET_DELIVERIES_SUCCESS,
  PICKUP,
  PICKUP_ERROR,
  PICKUP_SUCCESS,
} from "../actions/deliveries";
import type { clearExtraServices } from "../actions/extra_services";
import type { getTokenAction } from "../actions/get_token";
import type { getTour } from "../actions/get_tour";
import { GET_PATIENT_PAYMENT_SUCCESS } from "../actions/invoice";
import {
  AUTHENTICATE_PATIENT,
  AUTHENTICATE_PATIENT_SUCCESS,
  SIGN_PATIENT_OFFER,
  SIGN_PATIENT_OFFER_SUCCESS,
} from "../actions/offer";
import type { patientIsSavingSuccess } from "../actions/post_patient";
import type { getSmilesAction } from "../actions/smiles";
import type {
  addUserActionNotification,
  eraseUserActionNotification,
} from "../actions/user_notification";
import type { TOffer } from "./offer";

type InvoiceState = { link: string } | { invoice: { formUrl: string } };

type InvoiceAction = { type: "GET_INVOICE_DATA"; json: InvoiceState };

export function invoice(
  state: Partial<InvoiceState> = {},
  action: InvoiceAction,
): Partial<InvoiceState> {
  switch (action.type) {
    case "GET_INVOICE_DATA":
      return { ...state, ...action.json };

    case "ERASE_INVOICE_DATA":
      return {};

    default:
      return state;
  }
}

export type TUserActionNotification = ReactNotificationType & { message: MessageDescriptor["id"] };
type UserActionNotificationState = TUserActionNotification | null;
type UserActionNotificationAction = ReturnType<
  typeof addUserActionNotification | typeof eraseUserActionNotification
>;

export function userActionNotification(
  state: UserActionNotificationState = null,
  action: UserActionNotificationAction,
): UserActionNotificationState {
  switch (action.type) {
    case "ADD_USER_ACTION_NOTIFICATION":
      return { ...state, ...action.json };

    case "ERASE_USER_ACTION_NOTIFICATION":
      return null;

    default:
      return state;
  }
}

type ClinicAction =
  | { type: "GET_CLINIC_SUCCESS"; json: TClinic }
  | { type: "POST_CLINIC_SUCCESS"; json: TClinic };

export function clinic(state: Partial<TClinic> = {}, action: ClinicAction): Partial<TClinic> {
  switch (action.type) {
    case "GET_CLINIC_SUCCESS":
      return action.json;

    case "POST_CLINIC_SUCCESS":
      return action.json;

    default:
      return state;
  }
}

type ClinicsAction =
  | { type: "GET_CLINICS_SUCCESS"; json: TClinic[] }
  | { type: "UNLINK_CLINICS_SUCCESS"; clinic_id: string };

export function clinics(state: TClinic[] = [], action: ClinicsAction): TClinic[] {
  switch (action.type) {
    case "GET_CLINICS_SUCCESS":
      return action.json;

    case "UNLINK_CLINICS_SUCCESS":
      return state.filter((x) => x.clinic_id !== parseInt(action.clinic_id));

    default:
      return state;
  }
}

export function patientSaving(
  state = false,
  action: ReturnType<typeof patientIsSavingSuccess>,
): boolean {
  switch (action.type) {
    case "PATIENT_SAVE_SUCCESSFULL":
      return action.patientIsSaving;

    default:
      return state;
  }
}

export function fileUploaded(state = false, action) {
  switch (action.type) {
    case "FILE_UPLOAD_SUCCESSFULL":
      return action.fileUploaded;

    default:
      return state;
  }
}

export type TCorrectionMediaView = {
  linkto: string;
  thumbnail: string;
};

type PatientAction =
  | { type: "GET_PATIENT_SUCCESS"; json: TPatient }
  | {
      type: "ADD_PATIENT_SUCCESSFULL";
      json: Pick<TPatient, "first_name" | "last_name" | "patient_id">;
    }
  | {
      type: "UPDATE_PATIENT_SUCCESSFULL";
      json: Pick<TPatient, "first_name" | "last_name" | "patient_id">;
    }
  | { type: "POST_STATUS_END"; json: TPatient }
  | { type: "POST_STATUS_RETAINERS"; json: TPatient }
  | { type: "POST_PLAN_APPROVED"; json: TPatient }
  | { type: "POST_PLAN_SEND_TO_REWORK"; json: TPatient }
  | { type: "POST_BONUS_PATIENT"; json: TPatient }
  | { type: "ERASE_PATIENT"; json: Record<string, unknown> };

export function patient(state: Partial<TPatient> = {}, action: PatientAction): Partial<TPatient> {
  switch (action.type) {
    case "GET_PATIENT_SUCCESS":
      return action.json;
    case "ADD_PATIENT_SUCCESSFULL":
      return action.json;
    case "UPDATE_PATIENT_SUCCESSFULL":
      return action.json;
    case "POST_STATUS_END":
      return action.json;
    case "POST_STATUS_RETAINERS":
      return action.json;
    case "POST_PLAN_APPROVED":
      return action.json;
    case "POST_PLAN_SEND_TO_REWORK":
      return action.json;
    case "POST_BONUS_PATIENT":
      return action.json;
    case "ERASE_PATIENT":
      return {};
    default:
      return state;
  }
}

export function token(
  state: string | null = null,
  action: ReturnType<typeof getTokenAction>,
): string | null {
  switch (action.type) {
    case "GET_TOKEN_SUCCESS":
      return action.fields.token;

    default:
      return state;
  }
}

type PatientsState = {
  patients: TPatientList[];
  payment_alert: boolean;
  total: number;
  all: number;
};

export type TPatientList = {
  course: Pick<TPatient["course"], "course">;
  course_version: TPatient["course_version"];
  first_name: TPatient["first_name"];
  last_name: TPatient["last_name"];
  latest_correction: {
    approved_plan_id: TNone;
    price_value_paid: number;
    price_value_total: number;
    status: TPatient["status"];
    steps_count_completed: TCorrection["steps_count_completed"];
    steps_count_total: TCorrection["steps_count_total"];
    timestamp: TCorrection["timestamp"];
  };
  patient_id: TPatient["patient_id"];
  precise_initial_adjust_timestamp: string | null;
  precise_latest_adjust_timestamp: string | null;
  total_payments: TPatient["total_payments"];
};

const patientsInitialState: PatientsState = {
  patients: [],
  payment_alert: false,
  total: 0,
  all: 0,
};

type PatientsAction =
  | { type: "GET_PATIENTS_SUCCESS"; json: PatientsState }
  | { type: "LOAD_SPINNER" };

export function patients(
  state: PatientsState = patientsInitialState,
  action: PatientsAction,
): PatientsState {
  switch (action.type) {
    case "GET_PATIENTS_SUCCESS":
      return { ...state, ...action.json };

    case "LOAD_SPINNER":
      return { ...state, patients: [] };

    default:
      return state;
  }
}

export function meditPatients(state = [], action) {
  switch (action.type) {
    case "GET_MEDIT_PATIENTS_SUCCESS":
      return action.json;

    default:
      return state;
  }
}

type DoctorTasksState = {
  patients: TPatientList[];
  payment_alert: boolean;
  hasBeenLoaded: boolean;
};

type DoctorTasksAction = {
  type: "GET_DOCTORS_TASKS_SUCCESS";
  json: Pick<DoctorTasksState, "patients" | "payment_alert">;
};

export function doctorTasks(
  state: DoctorTasksState = { patients: [], payment_alert: false, hasBeenLoaded: false },
  action: DoctorTasksAction,
): DoctorTasksState {
  switch (action.type) {
    case "GET_DOCTORS_TASKS_SUCCESS":
      return { ...action.json, hasBeenLoaded: true };

    default:
      return state;
  }
}

export type TDoctorPayment = {
  course: TPatient["course"]["course"];
  course_option_tag: "CHILDREN_V3" | TNone;
  first_name: TPatient["first_name"];
  last_name: TPatient["last_name"];
  patient_id: TPatient["patient_id"];
  status: TPatient["status"];
  payments: {
    next: number;
    remain: number;
    tota: number;
    cbct?: boolean;
    extra?: TDoctorPaymentExtra[];
  };
  total_payments: {
    paid: number;
    total: number;
  };
};

type TDoctorPaymentExtra = {
  id: number;
  price: number;
  tag:
    | "ADDITIONAL_ALIGNERS"
    | "CBCT_ANALYSIS"
    | "DELIVERY"
    | "RETAINERS"
    | "RETAINERS_LOWER_ARCH"
    | "RETAINERS_UPPER_ARCH";
};

type DoctorPaysState = {
  patients: TDoctorPayment[];
  hasBeenLoaded: boolean;
};

type DoctorPaysAction = {
  type: "GET_DOCTORS_PAYS_SUCCESS";
  json: TDoctorPayment[];
};

export function doctorPays(
  state: DoctorPaysState = { patients: [], hasBeenLoaded: false },
  action: DoctorPaysAction,
): DoctorPaysState {
  switch (action.type) {
    case "GET_DOCTORS_PAYS_SUCCESS":
      return { patients: action.json, hasBeenLoaded: true };

    default:
      return state;
  }
}

type MediaState = Partial<Record<keyof TCorrectionMedia, TMediaValue>>;

type TMediaValue = {
  img: Record<string, unknown>;
  semantics: TCorrectionMediaSemantics;
  id: keyof TCorrectionMedia;
  patient_id: number;
  md5: string;
  status: "ok";
  user_filename?: string;
};

type MediaAction =
  | { type: "PATIENT_IMAGE_PREVIEW"; image: Omit<TMediaValue, "status" | "user_filename"> }
  | { type: "PATIENT_IMAGE_UPLOAD_SUCCESS"; json: Omit<TMediaValue, "img"> }
  | { type: "ADD_PATIENT_SUCCESSFULL" }
  | { type: "ERASE_MEDIA" };

export function media(state: MediaState = {}, action: MediaAction): MediaState {
  switch (action.type) {
    case "PATIENT_IMAGE_PREVIEW":
      return { ...state, [action.image.id]: action.image };

    case "PATIENT_IMAGE_UPLOAD_SUCCESS": {
      const ci = { ...state[action.json.id], ...action.json };
      return { ...state, [action.json.id]: ci };
    }

    // case "PATIENT_IMAGE_UPLOAD_ERROR":
    //   return Object.assign({}, state, { [action.json.id]: null, message: action.json.message })

    case "ADD_PATIENT_SUCCESSFULL":
      return {};

    case "ERASE_MEDIA":
      return {};

    default:
      return state;
  }
}

type MediaS3State = Partial<{
  uuid: string;
  files: string[];
}>;

type MediaS3Action =
  | { type: "media_s3/clear" }
  | { type: "CBCT_UPLOAD_SUCCESS"; payload: string[] };

export function media_s3(state: MediaS3State = {}, action: MediaS3Action): MediaS3State {
  switch (action.type) {
    case "media_s3/clear":
      return { uuid: uuidv4(), files: [] };

    case "CBCT_UPLOAD_SUCCESS":
      return { ...state, files: action.payload };

    default:
      return state;
  }
}

type DoctorAction = { type: "EDIT_DOCTOR_SUCCESS"; fields: TUser } | { type: "ERASE_DOCTOR" };

export function doctor(state: Partial<TUser> = {}, action: DoctorAction): Partial<TUser> {
  switch (action.type) {
    case "EDIT_DOCTOR_SUCCESS":
      return action.fields;

    case "ERASE_DOCTOR":
      return {};

    default:
      return state;
  }
}

type DeliveryState = {
  deliveries: TDelivery[];
  loading: boolean;
};

type DeliveryAction =
  | { type: typeof GET_DELIVERIES }
  | { type: typeof GET_DELIVERIES_ERROR }
  | { type: typeof GET_DELIVERIES_SUCCESS; fields: TDelivery[] }
  | { type: typeof PICKUP }
  | { type: typeof PICKUP_SUCCESS }
  | { type: typeof CREATE_SHIPMENT }
  | { type: typeof CREATE_SHIPMENT_SUCCESS; fields: TDelivery };

export function delivery(
  state: DeliveryState = { deliveries: [], loading: false },
  action: DeliveryAction,
): DeliveryState {
  switch (action.type) {
    case GET_DELIVERIES:
      return { ...state, loading: true };
    case GET_DELIVERIES_ERROR:
      return { ...state, deliveries: [], loading: false };
    case GET_DELIVERIES_SUCCESS:
      return { ...state, deliveries: action.fields, loading: false };

    case PICKUP:
      return { ...state, loading: true };
    case PICKUP_ERROR:
      return { ...state, loading: false };
    case PICKUP_SUCCESS:
      return { ...state, loading: false };

    case CREATE_SHIPMENT:
      return { ...state, loading: true };
    case CREATE_SHIPMENT_SUCCESS:
      return { deliveries: [action.fields, ...state.deliveries], loading: false };
    case CREATE_SHIPMENT_ERROR:
      return { deliveries: [], loading: false };

    /*
        case UPDATE_DELIVERY_INFO:
          return { deliveries: action.fields, loading: false };
        */

    default:
      return state;
  }
}

export type TPatientData = {
  course: {
    course: TPatient["course"]["course"];
    course_option_tag: TPatient["course"]["course_option_tag"];
    course_version: TPatient["course_version"];
    paid: number;
    payment_option: TPatient["course"]["payment_option"];
    payments: TPatient["course"]["payments"];
    services: unknown[];
    total: number;
    total_payments: TPatient["total_payments"];
  };
  offer: TOffer | null;
  patient_id: TPatient["patient_id"];
  signed: boolean;
};

type PatientDataAction =
  | { type: typeof AUTHENTICATE_PATIENT_SUCCESS }
  | { type: typeof SIGN_PATIENT_OFFER_SUCCESS; fields: TPatientData }
  | { type: typeof GET_PATIENT_PAYMENT_SUCCESS; fields: Omit<TPatientData, "course"> }
  | { type: typeof AUTHENTICATE_PATIENT };

export function patientData(
  state: Partial<TPatientData> = {},
  action: PatientDataAction,
): Partial<TPatientData> {
  switch (action.type) {
    case AUTHENTICATE_PATIENT_SUCCESS:
    case SIGN_PATIENT_OFFER_SUCCESS:
    case GET_PATIENT_PAYMENT_SUCCESS:
      if (action.fields) {
        return { ...action.fields };
      }
    case AUTHENTICATE_PATIENT:
    case SIGN_PATIENT_OFFER:
      return {};
    default:
      return state;
  }
}

type ServicesState = Record<string, unknown> | TService[];

type ServicesAction =
  | { type: "GET_EXTRA_SERVICES"; json: TService[] }
  | ReturnType<typeof clearExtraServices>;

export function services(state: ServicesState = {}, action: ServicesAction): ServicesState {
  switch (action.type) {
    case "GET_EXTRA_SERVICES":
      return action.json;

    case "CLEAR_EXTRA_SERVICES":
      return {};

    default:
      return state;
  }
}

type SpinnerAction = { type: "LOAD_SPINNER" } | { type: "CLEAR_SPINNER" };

export function spinner(state: boolean | null = null, action: SpinnerAction): boolean | null {
  switch (action.type) {
    case "LOAD_SPINNER":
      return true;

    case "CLEAR_SPINNER":
      return false;

    default:
      return state;
  }
}

export function fileSpinner(state = null, action) {
  switch (action.type) {
    case "LOAD_FILE_SPINNER":
      return true;

    case "CLEAR_FILE_SPINNER":
      return false;

    default:
      return state;
  }
}

export function fileUploadError(state = null, action) {
  switch (action.type) {
    case "UPLOAD_ERROR":
      return true;

    case "NO_ERROR":
      return false;

    default:
      return state;
  }
}

const CASES_STATE = {
  cases: [],
  count: null,
};

export function threeShapeCases(state = CASES_STATE, action) {
  switch (action.type) {
    case "GET_3SHAPE_CASES":
      return { ...state, ...action.json };

    default:
      return state;
  }
}

export function meditCases(state = [], action) {
  switch (action.type) {
    case "GET_MEDIT_CASES":
      return { ...action.json };

    default:
      return state;
  }
}

type BonusesState = {
  available: number;
  current: number;
  days: null;
  end: string;
  months: number;
  start: string;
  target: number;
  total: number;
  used: number;
};

type BonusesAction = { type: "GET_BONUSES"; json: BonusesState };

export function bonuses(
  state: Partial<BonusesState> = {},
  action: BonusesAction,
): Partial<BonusesState> {
  switch (action.type) {
    case "GET_BONUSES":
      return action.json || {};

    default:
      return state;
  }
}

export type TSmiles = {
  start: string;
  end: string;
  current: number;
  total: number;
};

export function smiles(
  state: Partial<TSmiles> = {},
  action: ReturnType<typeof getSmilesAction>,
): Partial<TSmiles> {
  switch (action.type) {
    case "GET_SMILES":
      return action.json;

    default:
      return state;
  }
}

export type TBanner = {
  id: number;
  type: "link" | "private_file" | "public_file" | "empty";
  position: number;
  align: "left" | "center" | "center_and_left";
  icon_url: string;
  banner_url: string | null;
  link: string | null;
  description: string | null;
};

export function banners(
  state: TBanner[] = [],
  action: ReturnType<typeof getBannersAction>,
): TBanner[] {
  switch (action.type) {
    case "GET_BANNERS":
      return action.json;

    default:
      return state;
  }
}

export type TShipment = {
  patient: Pick<TPatient, "patient_id" | "first_name" | "last_name">;
  tracking_number: string | null;
  pickup_date: string;
  type: "Major Express" | "UPS" | null;
};

type ShipmentsState = {
  data: TShipment[];
  hasBeenLoaded: boolean;
};

type ShipmentsAction = {
  type: "SHIPMENTS_SUCCESS";
  json: TShipment[];
};

export function shipments(
  state: ShipmentsState = { data: [], hasBeenLoaded: false },
  action: ShipmentsAction,
): ShipmentsState {
  switch (action.type) {
    case "SHIPMENTS_SUCCESS":
      return { data: action.json, hasBeenLoaded: true };

    default:
      return state;
  }
}

export function tour(state = false, action: ReturnType<typeof getTour>): boolean {
  switch (action.type) {
    case "GET_TOUR":
      return action.data;

    default:
      return state;
  }
}
