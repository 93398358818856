import { AnimatePresence, type AnimationProps, motion, useReducedMotion } from "framer-motion";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape, type MessageDescriptor } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { Redirect, type RouteComponentProps } from "react-router-dom";

import { eraseStateProp } from "~/actions/dashboard";
import { getPatientId } from "~/actions/get_patient_id";
import { orderRetainers } from "~/actions/order_retainers";
import { updateMedia } from "~/actions/post_patient";
import { SOArch, SOMaterial } from "~/common/constants";
import { remoteLog } from "~/common/logging";
import { getLastCorrection, isPatient } from "~/common/patient";
import { PatientNewInstructionsFiles } from "~/components/patient/patient_new/patient_new_instructions_files";
import { LabelledRadio, LabelledRadioGroup } from "~/components/ui/labelled-radio";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { LoadingButton } from "~/components/ui/loading-button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";

const mapStateToProps = (state: RootState) => ({
  patient: state.patient,
  media: state.media,
});

const mapDispatchToProps = {
  updateMedia,
  eraseStateProp,
  getPatientId,
  orderRetainers,
};

type PlanRetainersProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
    patient_id: string;
  }>;

type PlanRetainersState = {
  isSubmitting: boolean;
  material: SOMaterial | null;
  arch: SOArch | null;
  quantity: number | null;
};

class PlanRetainers extends Component<PlanRetainersProps, PlanRetainersState> {
  constructor(props: PlanRetainersProps) {
    super(props);
    this.state = {
      isSubmitting: false,
      material: null,
      arch: null,
      quantity: null,
    };
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "3d_plan_retainers_page_body");
  }

  componentDidMount() {
    this.props.eraseStateProp("media");

    const { patient_id } = this.props.match.params;
    void this.props.getPatientId(Number(patient_id));

    setDocumentTitle(
      this.props.intl.formatMessage({ id: "pat.retainers.page.header" }) + " " + patient_id,
    );
  }

  async orderRetainersSubmit() {
    const { patient_id } = this.props.match.params;
    const { material, arch, quantity } = this.state;

    const validity = this.validateRequiredFields();

    $("#instruction-files").css({ color: validity.files ? "#34495e" : "red" });
    $("#validation-material").css({ color: validity.material ? "#34495e" : "red" });
    $("#validation-arch").css({ color: validity.arch ? "#34495e" : "red" });
    $("#validation-quantity").css({ color: validity.quantity ? "#34495e" : "red" });

    if (Object.values(validity).some((v) => v == false)) {
      return;
    }

    this.setState({ isSubmitting: true });
    this.props.updateMedia(patient_id, { media: this.props.media });

    try {
      await this.props.orderRetainers(Number(patient_id), material, arch, quantity);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  validateRequiredFields() {
    const { material, arch, quantity } = this.state;

    const isScanUploaded = Object.entries(this.props.media).some(
      ([_, { user_filename }]) => user_filename?.endsWith(".stl"),
    );

    const areFilesValid = material != SOMaterial.USE_SCAN || isScanUploaded;
    const materialIsValid = material != null;
    const archIsValid = arch != null;
    const quantityIsValid = Boolean(quantity);

    return {
      files: areFilesValid,
      material: materialIsValid,
      arch: archIsValid,
      quantity: quantityIsValid,
    };
  }

  render() {
    const { patient } = this.props;
    const { material, isSubmitting } = this.state;

    if (!isPatient(patient)) {
      return (
        <Layout>
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        </Layout>
      );
    }

    const { can_order_retainers } = getLastCorrection(patient).order_options;

    if (!can_order_retainers) {
      return <Redirect to="/pages/patients" />;
    }

    return (
      <Layout>
        <div className="row">
          <div className="col-md-12">
            <Portlet as="main" id="retainers-section-head">
              <PortletTitle iconClassName="icon-book-open" displayRequiredFieldsInfo>
                <FormattedMessage id="pat.retainers.page.header" />
              </PortletTitle>

              <div className="portlet-body">
                <form
                  className="tw-space-y-5"
                  onSubmit={(event) => {
                    event.preventDefault();
                    void this.orderRetainersSubmit();
                  }}
                >
                  <MaterialRadioGroup
                    material={this.state.material}
                    onMaterialChange={(newMaterial) => this.setState({ material: newMaterial })}
                  />

                  <AnimatePresence>
                    {material == SOMaterial.USE_SCAN ? (
                      <ScanUploadRoot>
                        <PatientNewInstructionsFiles required />
                      </ScanUploadRoot>
                    ) : null}
                  </AnimatePresence>

                  <ArchRadioGroup
                    arch={this.state.arch}
                    onArchChange={(newArch) => this.setState({ arch: newArch })}
                  />

                  <QuantityRadioGroup
                    quantity={this.state.quantity}
                    onQuantityChange={(newQuantity) => this.setState({ quantity: newQuantity })}
                  />

                  <LoadingButton
                    id="submit-pacient-btn"
                    variant="primary"
                    size="xl"
                    isLoading={isSubmitting}
                  >
                    <FormattedMessage id="pat.payments.buttons.submit" />
                  </LoadingButton>
                </form>
              </div>
            </Portlet>
          </div>
        </div>
      </Layout>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(PlanRetainers));

function MaterialRadioGroup({
  material,
  onMaterialChange,
}: {
  material: SOMaterial | null;
  onMaterialChange(newMaterial: SOMaterial): void;
}) {
  type TOption = { value: SOMaterial; intlId: MessageDescriptor["id"] };

  const options: TOption[] = [
    { value: SOMaterial.LAST_STAGE, intlId: "EXTRA_SERVICE_OPTION_LAST_STAGE" },
    { value: SOMaterial.USE_SCAN, intlId: "EXTRA_SERVICE_OPTION_USE_SCAN" },
    { value: SOMaterial.USE_IMPRESSIONS, intlId: "EXTRA_SERVICE_OPTION_USE_IMPRESSIONS" },
  ];

  return (
    <LabelledRadioGroup
      label={<FormattedMessage id="ATTACHMENT_INSTRUCTIONS" />}
      labelProps={{ id: "validation-material" }}
      required
    >
      {options.map((option) => (
        <LabelledRadio
          key={option.value}
          label={<FormattedMessage id={option.intlId} />}
          name="material"
          value={option.value}
          checked={option.value == material}
          onChange={() => onMaterialChange(option.value)}
        />
      ))}
    </LabelledRadioGroup>
  );
}

function ArchRadioGroup({
  arch,
  onArchChange,
}: {
  arch: SOArch | null;
  onArchChange(newArch: SOArch): void;
}) {
  type TOption = { value: SOArch; intlId: MessageDescriptor["id"] };

  const options: TOption[] = [
    { value: SOArch.BOTH, intlId: "TA_BOTH" },
    { value: SOArch.UPPER, intlId: "TA_UPPER" },
    { value: SOArch.LOWER, intlId: "TA_LOWER" },
  ];

  return (
    <LabelledRadioGroup
      label={<FormattedMessage id="ARCHES_SELECT" />}
      labelProps={{ id: "validation-arch" }}
      required
    >
      {options.map((option) => (
        <LabelledRadio
          key={option.value}
          label={<FormattedMessage id={option.intlId} />}
          name="treat_arches_id"
          value={option.value}
          checked={option.value == arch}
          onChange={() => onArchChange(option.value)}
        />
      ))}
    </LabelledRadioGroup>
  );
}

function QuantityRadioGroup({
  quantity,
  onQuantityChange,
}: {
  quantity: number | null;
  onQuantityChange(newQuantity: number): void;
}) {
  const options = [
    { value: 1, text: "1" },
    { value: 2, text: "2" },
  ] as const;

  return (
    <LabelledRadioGroup
      label={<FormattedMessage id="RETAINERS_QUANTITY" />}
      labelProps={{ id: "validation-quantity" }}
      required
    >
      {options.map((option) => (
        <LabelledRadio
          key={option.value}
          label={option.text}
          name="retainers"
          value={option.value}
          checked={option.value == quantity}
          onChange={() => onQuantityChange(option.value)}
        />
      ))}
    </LabelledRadioGroup>
  );
}

function ScanUploadRoot({ children }: { children: React.ReactNode }) {
  const shouldReduceMotion = useReducedMotion();

  const animationProps: AnimationProps = {
    initial: { height: 0 },
    animate: { height: "auto", transition: { duration: 0.5 } },
    exit: { height: 0 },
  };

  return (
    <>
      <motion.div
        // PatientNewInstruionsFiles h4 adds a margin-top and padding-top that causes
        // the extra vertical space. Had to remove it from here.
        className="tw-overflow-hidden [&_h4]:tw-mt-0 [&_h4]:tw-pt-0"
        {...(shouldReduceMotion ? {} : animationProps)}
      >
        {children}
      </motion.div>
    </>
  );
}
