import clsx from "clsx";
import React, { Component } from "react";
import { FormattedMessage, injectIntl, type IntlShape } from "react-intl";
import Modal from "react-modal";
import { connect, type ConnectedProps } from "react-redux";
import { Redirect, type RouteComponentProps } from "react-router-dom";
import {
  Field,
  FieldArray,
  getFormValues,
  type InjectedFormProps,
  reduxForm,
  type WrappedFieldArrayProps,
  type WrappedFieldProps,
} from "redux-form";

import { orderAdditionalAligners } from "~/actions/additional_aligners";
import { getPatientId } from "~/actions/get_patient_id";
import { remoteLog } from "~/common/logging";
import { getLastCorrection, isPatient } from "~/common/patient";
import { RecipeCheckbox } from "~/components/patient/recipe/recipe-shared";
import { Button } from "~/components/ui/button";
import { IconButton } from "~/components/ui/icon-button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { LabelledRadio, LabelledRadioGroup } from "~/components/ui/labelled-radio";
import { Layout } from "~/components/ui/layout";
import { Loader } from "~/components/ui/loader";
import { LoadingButton } from "~/components/ui/loading-button";
import { Portlet, PortletTitle } from "~/components/ui/portlet";
import { setDocumentTitle } from "~/hooks/use-document-title";
import type { RootState } from "~/store";
import type { TPatient } from "~/types/patient";

type TAligner = typeof formInitialValues;
type TAlignersFormValues = { aligners: TAligner[] } | undefined;

const formInitialValues = {
  stage: "",
  amount: "",
  treat_arch_id: "",
  elastics_left: null,
  elastics_right: null,
};

function validate(values: { aligners?: TAligner[] }) {
  type TAlignersErrors = Partial<{ stage: "error"; amount: "error"; treat_arch_id: "error" }>;

  const errors: Partial<{ aligners: TAlignersErrors[] }> = {};
  const alignersArrayErrors: TAlignersErrors[] = [];

  if (values.aligners) {
    values.aligners.forEach((aligner, alignerIndex) => {
      const alignersErrors: TAlignersErrors = {};

      if (!aligner || !aligner.stage) {
        alignersErrors.stage = "error";
        alignersArrayErrors[alignerIndex] = alignersErrors;
      }

      if (!aligner || !aligner.amount) {
        alignersErrors.amount = "error";
        alignersArrayErrors[alignerIndex] = alignersErrors;
      }

      if (!aligner || !aligner.treat_arch_id) {
        alignersErrors.treat_arch_id = "error";
        alignersArrayErrors[alignerIndex] = alignersErrors;
      }
    });
  }

  if (alignersArrayErrors.length) {
    errors.aligners = alignersArrayErrors;
  }

  return errors;
}

const mapStateToProps = (state: RootState) => {
  return {
    patient: state.patient,
    formValues: getFormValues("additional_aligners")(state) as TAlignersFormValues,
  };
};

const mapDispatchToProps = {
  getPatient: getPatientId,
  orderAdditionalAligners,
};

type AdditionalAlignersProps = PropsFromRedux & { intl: IntlShape } & RouteComponentProps<{
    patient_id: string;
  }> &
  InjectedFormProps;

type AdditionalAlignersState = {
  isSubmitting: boolean;
  showModal: boolean;
};

class AdditionalAligners extends Component<AdditionalAlignersProps, AdditionalAlignersState> {
  constructor(props: AdditionalAlignersProps) {
    super(props);
    this.state = {
      isSubmitting: false,
      showModal: false,
    };
    this.renderForm = this.renderForm.bind(this);
    this.orderAdditionalAlignersSubmit = this.orderAdditionalAlignersSubmit.bind(this);
    this.required = this.required.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "additional_aligners_page_body");
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { patient_id } = this.props.match.params;
    void this.props.getPatient(Number(patient_id));

    setDocumentTitle(
      this.props.intl.formatMessage({ id: "pat.additional.aligners.page.header" }) +
        " " +
        patient_id,
    );
  }

  required(i: number, field: keyof TAligner) {
    if (this.props.formValues?.aligners[i]?.[field]) {
      return false;
    }
    return true;
  }

  async orderAdditionalAlignersSubmit() {
    const { aligners } = this.props.formValues as NonNullable<TAlignersFormValues>;
    if (aligners.length == 0) return;

    const patient = this.props.patient as TPatient;
    this.setState({ isSubmitting: true });

    try {
      await Promise.all(
        aligners.map((aligner) =>
          this.props.orderAdditionalAligners(
            patient.patient_id,
            parseInt(aligner.stage),
            parseInt(aligner.amount),
            parseInt(aligner.treat_arch_id),
            aligner.elastics_left,
            aligner.elastics_right,
          ),
        ),
      );
    } catch {
      this.setState({ isSubmitting: false });
    }
  }

  renderElastics(field: string) {
    return (
      <div id="additional-aligners-elastics-section">
        <Label id="elastics-heading" readOnly>
          <FormattedMessage id="HEADER_ELASTICS" />
        </Label>

        <div className="row">
          <div className="form-group col-md-5 col-sm-3">
            <Label id="elastics-radio-left-label" readOnly>
              <FormattedMessage id="ELASTICS_LEFT" />
            </Label>

            <div className="checkbox-list checkbox_list">
              <RecipeCheckbox
                name={`${field}elastics_left`}
                value={2}
                intlId="ER_L_II_CLASS"
                ariaLabelledBy={["elastics-heading", "elastics-radio-left-label"]}
              />

              <RecipeCheckbox
                name={`${field}elastics_left`}
                value={3}
                intlId="ER_L_III_CLASS"
                ariaLabelledBy={["elastics-heading", "elastics-radio-left-label"]}
              />
            </div>
          </div>

          <div className="form-group col-md-5 col-sm-3">
            <Label id="elastics-radio-right-label" readOnly>
              <FormattedMessage id="ELASTICS_RIGHT" />
            </Label>

            <div className="checkbox-list checkbox_list">
              <RecipeCheckbox
                name={`${field}elastics_right`}
                value={2}
                intlId="ER_R_II_CLASS"
                ariaLabelledBy={["elastics-heading", "elastics-radio-right-label"]}
              />

              <RecipeCheckbox
                name={`${field}elastics_right`}
                value={3}
                intlId="ER_R_III_CLASS"
                ariaLabelledBy={["elastics-heading", "elastics-radio-right-label"]}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderForm({ fields, meta: { submitFailed } }: WrappedFieldArrayProps) {
    const patient = this.props.patient as TPatient;

    return (
      <div>
        {fields.map((field, i) => (
          <div key={i} className="portlet-body">
            <div className="form-group">
              <Label
                htmlFor="stage"
                required
                red={this.required(i, "stage") && submitFailed}
                icon={
                  i > 0 ? (
                    <IconButton srOnlyText="Удалить этап" onClick={() => fields.remove(i)}>
                      <i className="icon-trash" style={{ color: "#ff0000", fontSize: "16px" }} />
                    </IconButton>
                  ) : null
                }
              >
                <FormattedMessage id="STAGE" />
              </Label>

              <Field
                name={`${field}stage`}
                component={ReduxFormStageSelect}
                normalize={(v: string) => (v >= "0" ? v : null)}
                maxStage={getLastCorrection(patient).steps_count_completed}
              />
            </div>

            <LabelledRadioGroup
              label={<FormattedMessage id="SELECT_TREAT_ARCHES" />}
              labelProps={{
                id: `treat_arch_id${i}`,
                red: this.required(i, "treat_arch_id") && submitFailed,
              }}
              required
            >
              <Field
                name={`${field}treat_arch_id`}
                component={ReduxFormLabelledRadio}
                label={
                  <>
                    <FormattedMessage id="TA_BOTH" />
                    &nbsp;&nbsp;
                    <FormattedMessage id="additional.aligners.both.comment" />
                  </>
                }
                radioValue={1}
              />

              <Field
                name={`${field}treat_arch_id`}
                component={ReduxFormLabelledRadio}
                label={<FormattedMessage id="TA_UPPER" />}
                radioValue={2}
              />

              <Field
                name={`${field}treat_arch_id`}
                component={ReduxFormLabelledRadio}
                label={<FormattedMessage id="TA_LOWER" />}
                radioValue={3}
              />
            </LabelledRadioGroup>

            <div className="form-group">
              <div className="form-group">
                <Label htmlFor="amount" required red={this.required(i, "amount") && submitFailed}>
                  <FormattedMessage id="AMOUNT" />
                </Label>

                {this.props.formValues?.aligners[i]?.treat_arch_id === "1" ? (
                  <div className="row">
                    <div className="col-md-4">
                      <Label htmlFor="amount_upper" semibold>
                        <FormattedMessage id="UPPER_ARCH" />
                      </Label>

                      <Field
                        name={`${field}amount`}
                        component={ReduxFormInput}
                        normalize={(v: string) => (v >= "1" ? v : null)}
                        id="amount_upper"
                        type="number"
                        className="tw-max-w-[240px]"
                        min={1}
                      />
                    </div>

                    <div className="col-md-4">
                      <Label htmlFor="amount_lower" semibold>
                        <FormattedMessage id="LOWER_ARCH" />
                      </Label>

                      <Field
                        name={`${field}amount`}
                        component={ReduxFormInput}
                        normalize={(v: string) => (v >= "1" ? v : null)}
                        id="amount_lower"
                        type="number"
                        className="tw-max-w-[240px]"
                        min={1}
                      />
                    </div>
                  </div>
                ) : (
                  <Field
                    name={`${field}amount`}
                    component={ReduxFormInput}
                    normalize={(v: string) => (v >= "1" ? v : null)}
                    id="amount"
                    type="number"
                    className="tw-max-w-[240px]"
                    min={1}
                  />
                )}
              </div>

              {this.renderElastics(field)}
            </div>
          </div>
        ))}

        <Button
          variant="secondary"
          style={{ marginBottom: "10px" }}
          id="add-btn"
          onClick={(e) => {
            e.preventDefault();
            fields.push(formInitialValues);
          }}
        >
          <FormattedMessage id="pat.additional.aligners.add.stage" />
        </Button>
      </div>
    );
  }

  openModal(invalid: boolean) {
    if (invalid) {
      this.setState({ showModal: true });
    }
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { patient, handleSubmit, submitting, invalid, formValues } = this.props;

    if (!isPatient(patient)) {
      return (
        <Layout>
          <div className="row">
            <div className="col-md-12">
              <Loader />
            </div>
          </div>
        </Layout>
      );
    }

    const { can_order_additional_aligners } = getLastCorrection(patient).order_options;

    if (!can_order_additional_aligners) {
      return <Redirect to="/pages/patients" />;
    }

    const message =
      formValues && formValues.aligners.length > 1
        ? "pat.additional.aligners.required.fields.remove.stage"
        : "pat.additional.aligners.required.fields";

    return (
      <Layout>
        <div className="row">
          <div className="col-md-12">
            <Portlet as="main">
              <PortletTitle iconClassName="icon-book-open" displayRequiredFieldsInfo>
                <FormattedMessage id="pat.additional.aligners.page.header" />
              </PortletTitle>

              <form
                onSubmit={
                  handleSubmit(this.orderAdditionalAlignersSubmit) as React.FormEventHandler
                }
              >
                <Modal
                  isOpen={this.state.showModal}
                  ariaHideApp={false}
                  className="question-modal"
                  onRequestClose={() => this.closeModal()}
                  style={{
                    overlay: {
                      backgroundColor: "#787d7d7d",
                    },
                    content: {
                      outline: "none",
                    },
                  }}
                >
                  <div
                    style={{ outline: "none", border: "1px solid #32c5d2" }}
                    className="portlet-body form"
                    id="comment-question-modal"
                  >
                    <div className="form-body">
                      <div className="form-group">
                        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                        <label className="control-label" id="comment-question-modal-label">
                          <FormattedMessage id={message} />
                        </label>

                        <button onClick={() => this.closeModal()} type="button" className="close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal>

                <FieldArray name="aligners" component={this.renderForm} />

                <div>
                  <LoadingButton
                    id="submit-pacient-btn"
                    type="submit"
                    variant="primary"
                    disabled={submitting}
                    onClick={() => this.openModal(invalid)}
                    isLoading={this.state.isSubmitting}
                  >
                    <FormattedMessage id="pat.payments.buttons.submit" />
                  </LoadingButton>
                </div>
              </form>
            </Portlet>
          </div>
        </div>
      </Layout>
    );
  }
}

AdditionalAligners = reduxForm({
  form: "additional_aligners",
  initialValues: { aligners: [formInitialValues] },
  validate,
  enableReinitialize: true,
})(AdditionalAligners);

AdditionalAligners = connect((state) => ({
  formValues: getFormValues("additional_aligners")(state),
  shouldValidate: () => true,
}))(AdditionalAligners);

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(injectIntl(AdditionalAligners));

function ReduxFormLabelledRadio({
  input,
  meta: _,
  radioValue,
  ...labelledRadioProps
}: WrappedFieldProps & { label: React.ReactChild; radioValue: number }) {
  return (
    <LabelledRadio
      {...input}
      {...labelledRadioProps}
      value={`${radioValue}`}
      checked={input.value == radioValue}
    />
  );
}

function ReduxFormInput({ input, meta: _, ...inputProps }: WrappedFieldProps) {
  return <Input {...input} {...inputProps} />;
}

function ReduxFormStageSelect({ input, maxStage }: WrappedFieldProps & { maxStage: number }) {
  return (
    <select
      {...input}
      id="stage"
      className={clsx(
        "form-control tw-max-w-[240px] tw-border tw-border-[#c2cad8]",
        "tw-outline-none tw-ring-blue-600 tw-transition-all focus-visible:tw-ring-1",
        "max-sm:tw-text-base",
      )}
    >
      <option></option>

      {Array.from({ length: maxStage + 1 }, (_, i) => (
        <option key={i} value={i}>
          {i}
        </option>
      ))}
    </select>
  );
}
