import * as Tooltip from "@radix-ui/react-tooltip";
import clsx from "clsx";
import React, { useState } from "react";

type TooltipContentProps = React.ComponentPropsWithoutRef<typeof Tooltip.Content>;
type TooltipProps = Pick<TooltipContentProps, "side" | "className" | "children">;
type QuestionTooltipProps = TooltipProps & { marginLeft?: boolean; maxWidth?: boolean };

export function QuestionTooltip({
  marginLeft = true,
  maxWidth = true,
  side = "top",
  className,
  children,
}: QuestionTooltipProps) {
  return (
    <Tooltip.Provider delayDuration={0}>
      <TooltipRoot>
        <Tooltip.Trigger
          className={clsx(
            "tw-cursor-default tw-border-none tw-bg-transparent tw-px-0",
            { "tw-ml-1.5": marginLeft },
            className,
          )}
        >
          <i className="icon-question tw-text-[14px]" />
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content
            className={clsx(
              "tw-rounded tw-bg-[#f7f7f7] tw-p-2.5 tw-text-xs tw-shadow-md",
              "motion-safe:tw-duration-300 motion-safe:tw-animate-in",
              "data-[state=delayed-open]:tw-fade-in-0",
              "data-[state=delayed-open]:tw-slide-in-from-top-1",
              { "tw-max-w-[375px]": maxWidth },
            )}
            sideOffset={5}
            side={side}
          >
            {children}
          </Tooltip.Content>
        </Tooltip.Portal>
      </TooltipRoot>
    </Tooltip.Provider>
  );
}

function TooltipRoot({
  alwaysOpen,
  children,
}: {
  alwaysOpen?: boolean;
  children: React.ReactNode;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Tooltip.Root open={alwaysOpen || open} delayDuration={0} onOpenChange={setOpen}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div style={{ display: "inline-block" }} onClick={() => setOpen(true)}>
        {children}
      </div>
    </Tooltip.Root>
  );
}
