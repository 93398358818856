import React from "react";
import { FormattedMessage } from "react-intl";

import FileUpload from "~/components/file_upload";
import { Label } from "~/components/ui/label";

export default function PatientNewInstructionsImages({
  displayHeading = true,
  correctionNumber,
}: {
  displayHeading?: boolean;
  correctionNumber?: number;
}) {
  return (
    <div data-matomo-mask data-hj-suppress className="form-body" id="photo-protocol-block">
      {displayHeading ? (
        <Label
          id="validation-course_id"
          size="xl"
          readOnly
          required
          tooltip={<FormattedMessage id="upload.photos.tooltip" />}
        >
          <FormattedMessage id="UPLOAD_FHOTOS" />
        </Label>
      ) : null}

      <div className="row">
        <div className="form-group col-md-4">
          <Label id="photo-profile-label" required readOnly>
            <FormattedMessage id="PHOTO_PROFILE" />
          </Label>

          <FileUpload
            preview
            id="profile"
            semantics="profile"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-profile-label"
          />
        </div>

        <div className="form-group col-md-4">
          <Label id="photo-front-smile-label" required readOnly>
            <FormattedMessage id="PHOTO_SMILE" />
          </Label>

          <FileUpload
            preview
            id="full_face_with_smile"
            semantics="full_face_with_smile"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-front-smile-label"
          />
        </div>

        <div className="form-group col-md-4">
          <Label id="photo-front-no-smile-label" required readOnly>
            <FormattedMessage id="PHOTO_PORTRATE" />
          </Label>

          <FileUpload
            preview
            id="full_face_without_smile"
            semantics="full_face_without_smile"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-front-no-smile-label"
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: 10 }}>
        <div className="form-group col-md-4" style={{ marginBottom: 10 }}>
          <Label id="photo-upper-occlusal-label" required readOnly>
            <FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
          </Label>

          <FileUpload
            preview
            id="occlusal_view_upper"
            semantics="occlusal_view_upper"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-upper-occlusal-label"
          />
        </div>

        <div className="form-group col-md-4"></div>

        <div className="form-group col-md-4">
          <Label id="photo-lower-occlusal-label" required readOnly>
            <FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
          </Label>

          <FileUpload
            preview
            id="occlusal_view_lower"
            semantics="occlusal_view_lower"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-lower-occlusal-label"
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: 10 }}>
        <div className="form-group col-md-4">
          <Label id="photo-lateral-right-label" required readOnly>
            <FormattedMessage id="PHOTO_RIGHT" />
          </Label>

          <FileUpload
            preview
            id="lateral_view_right"
            semantics="lateral_view_right"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-lateral-right-label"
          />
        </div>

        <div className="form-group col-md-4">
          <Label id="photo-intraoral-front-label" required readOnly>
            <FormattedMessage id="PHOTO_FRONT" />
          </Label>

          <FileUpload
            preview
            id="front_view"
            semantics="front_view"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-intraoral-front-label"
          />
        </div>

        <div className="form-group col-md-4">
          <Label id="photo-lateral-left" required readOnly>
            <FormattedMessage id="PHOTO_LEFT" />
          </Label>

          <FileUpload
            preview
            id="lateral_view_left"
            semantics="lateral_view_left"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-lateral-left"
          />
        </div>
      </div>
    </div>
  );
}
